import axios from 'axios';
// console.log('Base URL:', process.env.REACT_APP_API_URL); 
const httpClient = axios.create({
    baseURL: process.env.REACT_APP_API_URL,   //for develop
    // baseURL: "http://localhost:8000",   //for local
});

httpClient.interceptors.request.use(function (config) {
    const token = localStorage.getItem('user-token');
    config.headers.Authorization = token ? `Bearer ${token}` : '';
    return config;
}, function (error) { return Promise.reject(error); });

httpClient.interceptors.response.use(function (response) {
    return response;
}, function (error) {
    console.log(error)
    if (error.response && (error.response.status === 403 || error.response.status === 401)) {
        localStorage.removeItem('user-token');
        window.location.href = `${process.env.REACT_APP_API_URL}/login?redirect=2`;  //for develop
        // window.location.href = `http://localhost:8000/login?redirect=2`;  //for local
    }
    else if(error.response && (error.response.status === 419)){
        localStorage.removeItem('user-token');
         window.location.href = `${process.env.REACT_APP_API_URL}/login?redirect=2&session_expired=${error?.response?.data?.message}`; //for develop
        // window.location.href = `http://localhost:8000/login?redirect=2&session_expired=${error.response.data.message}`; //local
        
    }
    return Promise.reject(error);
});

export default httpClient;