import React, { useState } from 'react';
import { Timeline } from 'primereact/timeline';
import StatusUpdateDialog from './StatusUpdateDialog';
import httpClient from '../core/api';
import Icon from './Icon';
import DetailTimelineDialog from './Modal/DetailTimelineDialog';
const CustomTimeline = ({ events,onRefresh ,locationValue,laststatusid,zoomOutVisible}) => {
    const [dialogVisible, setDialogVisible] = useState(false);
    const [visible, setVisible] = useState(false);
    const [timelinedetail, setTimelineDetail] = useState([]);
    const [laststatusdata,setLastStatusData]=useState();
    const getClassByDescription = (description) => {
      if (description===100) {
        return 'first';
      } else if (description===75) {
        return 'second';
      } 
      else if (description===50) {
        return 'third';
      } 
      else if (description===25) {
        return 'fourth';
      } 
      else if (description===0) {
        return 'fifth';
      }else {
        return '';
      }
    };
    const handleViewDetails = (item) => {
      setTimelineDetail(item); 
      setVisible(true);
    };
    const customizedMarker = (item) => {
      const itemClass = getClassByDescription(item.staffing);
      return (
        <span className={`p-timeline-event-marker ${itemClass}`}></span>
      );
    };
    const customizedContent = (item) => {
      const itemClass = getClassByDescription(item.staffing);
      return (
        <div className={`history-timeline-content  ${itemClass}`}>
          <div className='timeline-heading flex justify-content-between align-items-end'><span>{item?.issue_detail?item?.issue_detail:"None"}
            </span>
             <div className='sidepanel-details' onClick={() => handleViewDetails(item)}> <span>View Details</span>  <Icon
                                className="cursor-pointer"
                                type="svg"
                                name="map-chevron-right"
                            /></div>
           </div>
           
          <div className=' timeline-desc pb-5'>
            <span className='newtimeline-text-color'>{item?.updated_at}-{item?.created_by_name}</span>
            <div className='timeline-heading2  flex justify-content-between mt-2'>
              <span className='timeline-heading2'>Days Behind</span>
              <span className='timeline-heading2'>{item?.backlog}</span>
            </div>

          </div>
        </div>
      );
    };
  
const showDialog = async() => {

    try {
      const res = await httpClient.get(`/api/location-statuses/get/${laststatusid}`);
      if(res.status===200){
        setLastStatusData(res?.data[0])
      }
    } catch (error) {
      console.log(error);
    }

  
    setDialogVisible(true);
  };

  const hideDialog = () => {
    setDialogVisible(false);
  };
  
  const handleStatusSubmit = async (stateName, id) => {
    await onRefresh(stateName, id); 
    setDialogVisible(false); 
  };
  const DetailModalHide = () => {
    setVisible(false)
};
const timelineStyle = {
  height: zoomOutVisible ? 'calc(100vh - 330px)' : 'calc(100vh - 360px)',
};

  return (
    <div className="timeline-demo p-2" style={timelineStyle} >
         
      <div className="card">
        <div className='flex justify-content-between align-items-center mb-4'>
        <span  className='text-base '>Update Status</span>
        <div className='map-icon mt-1'>
        <i className="pi pi-plus cursor-pointer" style={{ fontSize: '1rem'}} onClick={showDialog}></i>
        </div>   
        </div>
      
       <Timeline align="left" className='' value={events} content={customizedContent} marker={customizedMarker}/>
       <StatusUpdateDialog visible={dialogVisible} onHide={hideDialog} onRefresh={handleStatusSubmit} locationValue={locationValue} laststatus={laststatusdata}/>
       <DetailTimelineDialog  details={timelinedetail} visible={visible} onHide={DetailModalHide} />
      </div>
    </div>
  );
};

export default CustomTimeline;
